import React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
// import {history} from "react-router";
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import ChangePassword from "./components/Change Password/ChangePassword";
// import Report from "./components/Report/Report";
import Slitter from "./components/Report/sllitter";
import Enery from "./components/Report/enery";
import ProcessWiseAnalysis from "./components/Report/ProcessWiseAnalysis";
import DistributionWiseAnalysis from "./components/Report/distributionWiseAnalysis";
import SmartMeter from "./components/Report/smartmeter"
// import DistributionNetwork from "./components/Report/distributionNetwork";
import DistributionNetwork from "./components/Report/Grafana/DistributionNetwork";
import EMSOverview from "./components/Report/EMSOverview";
// import PlantConnect from "./components/Report/PlantConnect";
import Smlr from "./components/Report/SMLR";
// import TimeSeries from "./components/Report/Grafana";
import Connectivity from "./components/Report/Grafana/PlantConnectivity";
import PowerConsumption from "./components/Report/PowerConsumption";
import OEECalenderCoaterCFB from "./components/Report/OEECalenderCoaterCFB";
import "./App.css";
import ECRMISReport from "./components/Report/ECRMIS";
function App(props) {
  console.clear();
  console.log("history");
  // console.log(history);
  // useEffect(() => {
    
  //   window.addEventListener("beforeunload", (ev) => {
  //     ev.preventDefault();
  //     return (ev.returnValue = "Are you sure you want to close?");
  //   });
  // }, []);
  return (
    <div className="">
      <BrowserRouter history="">
        <header className=""></header>
        <Switch>

        {/* {(localStorage.getItem('EnergyManagement') == 'true') ? */} 
        <Route exact path="/DistributionNetwork"> 
            <Dashboard>
              <DistributionNetwork />
            </Dashboard>
        </Route> 
        {/* : null} */}
          
          {/* {(localStorage.getItem('EnergyManagement') == 'true') ?  */}
          <Route exact path="/EMSOverview">
            <Dashboard>
              <EMSOverview />
            </Dashboard>
          </Route> 
          {/* : null} */}

          
          <Route exact path="/OEE">
            <Dashboard>
              <OEECalenderCoaterCFB />
            </Dashboard>
          </Route> 

          {/* {(localStorage.getItem('EnergyManagement') == 'true') ?  */}
          <Route exact path="/Smlr">
            <Dashboard>
              <Smlr />
            </Dashboard>
          </Route>
           {/* : null} */}



          {/* <Route exact path="/home">
            <Dashboard>
              <Report />
            </Dashboard>
          </Route> */}

          {/* {(localStorage.getItem('SlitterOEE') == 'true') ? */}
           <Route exact path="/slitter">
            <Dashboard>
              <Slitter />
            </Dashboard>
          </Route>
          {/* : null} */}


          {/* <Route exact path="/en1m">
            <Dashboard>
              <Enery />
            </Dashboard>
          </Route> */}

          {/* {(localStorage.getItem('EnergyManagement') == 'true') ?  */}
          <Route exact path="/ProcessWiseAnalysis">
            <Dashboard>
              <ProcessWiseAnalysis />
            </Dashboard>
          </Route>
           {/* : null} */}

          {/* {(localStorage.getItem('EnergyManagement') == 'true') ?  */}
          <Route exact path="/DistributionWiseAnalysis">
            <Dashboard>
              <DistributionWiseAnalysis />
            </Dashboard>
          </Route> 
          {/* : null} */}

          
          <Route exact path="/PlantConnectivity">
            <Dashboard>
              {/* <PlantConnect /> */}
              <Connectivity />
            </Dashboard>
          </Route>

          <Route exact path="/PowerConsumption">
            <Dashboard>
              <PowerConsumption />
            </Dashboard>
          </Route>
          <Route exact path="/ecrmis">
            <Dashboard>
              <ECRMISReport />
            </Dashboard>
          </Route>
          {/* <Route exact path="/Connectivity">
            <Dashboard>
              <Connectivity />
            </Dashboard>
          </Route> */}

          {/* <Route exact path="/TimeSeriesValuesReport">
            <Dashboard>
              <TimeSeries />
            </Dashboard>
          </Route>

          <Route exact path="/OverviewReport">
            <Dashboard>
              <TimeSeries />
            </Dashboard>
          </Route> */}
          
          {/* <Route exact path="/SmartMeter">
            <Dashboard>
              <SmartMeter />
            </Dashboard>
          </Route> */}

          <Route exact path="/settings/Change-password">
            <Dashboard>
              <ChangePassword />
            </Dashboard>
          </Route>
          <Route exact path="/" component={Login} />

        </Switch>

        <div className="content"></div>
        <footer></footer>
      </BrowserRouter>
    </div>
  );
}

export default App;