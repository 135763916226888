import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Modal, Box, Button } from "@mui/material";
import apijson from "../../../config/api.config.json";
import "./style.css";

// Helper function to convert date key format (YYYYMMDD) to header format (DD-MM-YYYY)
const formatDateForHeader = (dateKey) => {
  const dateStr = dateKey.toString();
  const year = dateStr.slice(0, 4);
  const month = dateStr.slice(4, 6);
  const day = dateStr.slice(6, 8);
  return `${day}-${month}-${year}`;
};

// Validation schema for numeric input
const validationSchema = Yup.object().shape({
  machines: Yup.array().of(
    Yup.object().shape({
      dates: Yup.array().of(
        Yup.object().shape({
          value: Yup.string()
            .matches(/^\d*$/, "Only numeric values are allowed")
            .nullable(),
        })
      ),
    })
  ),
});

const ECRMISAddEntry = () => {
  const [open, setOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch data from API
  const getECRData = async () => {
    try {
      const response = await axios.get(apijson.GET_ECR_REPORT_DATA, {
        headers: {
          "x-access-token":
            localStorage.getItem('token')
        },
      });
      if (response.data.status === 1) {
        const data = response.data.data;
        const formattedData = data.map((group) => ({
          MachineGroupKey: group.MachineGroupKey,
          MachineGroupName: group.MachineGroupName,
          dates: group.dates.map((date) => ({
            key: Object.keys(date)[0],
            value: date[Object.keys(date)[0]],
          })),
        }));

        setInitialValues({ machines: formattedData });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getECRData();
  }, []);

  const handleSubmit = async (values) => {
    const formattedData = values.machines.map((group) => ({
      MachineGroupKey: group.MachineGroupKey,
      MachineGroupName: group.MachineGroupName,
      dates: group.dates.map((date) => ({
        [date.key]: date.value,
      })),
    }));

    try {
      const response = await axios.post(apijson.POST_ECR_REPORT_DATA, formattedData, {
        headers: {
          "x-access-token":
            localStorage.getItem('token')
        },
      });
      if (response.data.status === 1) {
        setOpen(false);
        getECRData();
        alert(response.data.message);
      } else {
        alert("Failed to save data.");
      }
    } catch (error) {
      console.error("Error saving data:", error);
      alert("An error occurred while saving data.");
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button variant="contained" className="ecrAddBtn" onClick={handleOpen}>
        Add Entry
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "#2f2f2f",
            color: "#ABC",
            // border: "1px solid gray",
            boxShadow: 24,
            borderRadius: 2,
            p: 4,
            // overflowY: "auto",
            maxHeight: "90vh",
          }}
        >
          <h2
            style={{
              // borderBottom: "1px solid gray",
              paddingBottom: "10px",
              textAlign: "center",
            }}
          >
            ECR-MIS Report Entry Form
          </h2>

          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values }) => (

                <Form>
                  <div className="table-container">
                    <table
                      border="1"
                      style={{
                        borderCollapse: "collapse",
                        width: "100%",
                        color: "#ABC",
                        backgroundColor: "#1f1f1f",
                      }}
                    >
                      <thead>
                        <tr style={{ borderBottom: "1px solid #2f2f2f" }}>
                          <th>Machine Name</th>
                          {values.machines[0].dates.map((date) => (
                            <th key={date.key}>{formatDateForHeader(date.key)}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {values.machines.map((machine, machineIndex) => (
                          <tr
                            key={machine.MachineGroupKey}
                            style={{ borderBottom: "1px solid #2f2f2f" }}
                          >
                            <td>{machine.MachineGroupName}</td>
                            {machine.dates.map((date, dateIndex) => (
                              <td key={date.key}>
                                <Field
                                  name={`machines[${machineIndex}].dates[${dateIndex}].value`}
                                  placeholder="Enter Value"
                                  style={{
                                    color: "#ABC",
                                    backgroundColor: "#2f2f2f",
                                    // border: "1px solid gray",
                                    padding: "5px",
                                  }}
                                />
                                <ErrorMessage
                                  name={`machines[${machineIndex}].dates[${dateIndex}].value`}
                                  component="div"
                                  style={{ color: "#d95454", fontSize: "10px", marginTop: "3px" }}

                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      marginTop: 2,
                      bgcolor: "#ABC",
                      color: "#2f2f2f",
                      "&:hover": { bgcolor: "#ABC" },
                      float: "right",
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    type="button"
                    onClick={handleClose}
                    variant="contained"
                    sx={{
                      marginTop: 2,
                      marginRight: 2,
                      bgcolor: "#d3d3d3",
                      color: "#2f2f2f",
                      "&:hover": { bgcolor: "#d3d3d3" },
                      float: "right",
                    }}
                  >
                    Close
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ECRMISAddEntry;
